table {
    table-layout: auto;
    width: auto !important;
}

td, th {
    width: 0%;
    white-space: nowrap;
    text-align: center;
}

td:first-child, th:first-child {
    text-align: start;
}