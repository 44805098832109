$white: #ffffff;

$theme-colors: (
    "light":      #e5eff7,
    "dark":       #181c30,
    "primary":    #083e5c,
    "secondary":  #3e535f,
    "info":       #983e5f,
    "success":    #0c9752,
    "warning":    #e1c70e,
    "danger":     #a50208,
);

html, 
body,
#root {
    height: 100%;
    overflow: hidden;
}

#root {
    align-items: center;
    background-color: map-get($theme-colors, "dark");
}

// Hide doubled react-bootstrap-typeahead close button
.rbt-close-content {
    display: none;
}

/* import bootstrap to set changes */
@import "../../node_modules/bootstrap/scss/bootstrap";
@import 'react-bootstrap-typeahead/css/Typeahead.css';