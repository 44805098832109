.token-table > thead > tr > th {
    &:first-child {
        width: 100%;
    }
    &:not(:first-child) {
        width: 1%;
        white-space: nowrap;
    }
}

.token-table tr td {
    vertical-align: middle;
}