@import 'react-calendar/dist/Calendar.css';
@import 'react-date-picker/dist/DatePicker.css';

.react-date-picker__inputGroup {
    min-width: 0;
}

.react-date-picker__wrapper {
    border: 0;
}
